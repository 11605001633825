import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "reset-password-confirm",
    path: `${AUTH_PREFIX_PATH}/reset-password-confirm`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/reset-password-confirm")
    ),
  },
];

export const getProtectedRoutes = (
  companyType,
  department,
  biAccess,
  stages
) => {
  return [
    // Monitoring routes
    // 1. Home
    {
      key: "home",
      path: `${APP_PREFIX_PATH}/home`,
      component: React.lazy(() => import("views/app-views/home")),
      access: true,
    },

    // 2. Asset Overview
    {
      key: "overview.assets",
      path: `${APP_PREFIX_PATH}/overview/assets/:stageName/:stageType`,
      component: React.lazy(() => import("views/app-views/assetsOverview")),
      access: true,
    },
    {
      key: "timeline.assets",
      path: `${APP_PREFIX_PATH}/timeline/assets/:stageName/:stageType`,
      component: React.lazy(() => import("views/app-views/assetsOverview")),
      access: companyType === "Brewery",
    },

    // 3. Asset Details
    {
      key: "asset.details",
      path: `${APP_PREFIX_PATH}/:stage/asset/:pk/details`,
      component: React.lazy(() => import("views/app-views/assetDetails")),
      access: true,
    },

    // 4. Insights
    {
      key: "insights",
      path: `${APP_PREFIX_PATH}/insights`,
      component: React.lazy(() => import("views/app-views/insights")),
      access: companyType !== "Generic",
    },

    // 5. Events
    {
      key: "events",
      path: `${APP_PREFIX_PATH}/events`,
      component: React.lazy(() => import("views/app-views/events")),
      access: companyType !== "Generic",
    },

    // 6. Line Monitoring
    {
      key: "line.monitoring",
      path: `${APP_PREFIX_PATH}/line-monitoring/:pk`,
      component: React.lazy(() => import("views/app-views/lineMonitoring")),
      access: stages && stages.some((stage) => stage.type === "Packaging"),
    },

    // 7. Production Scheduler
    {
      key: "production.scheduler",
      path: `${APP_PREFIX_PATH}/production-scheduler/:stagePk`,
      component: React.lazy(() =>
        import("views/app-views/productionScheduler")
      ),
      access: stages && stages.some((stage) => stage.type === "Packaging"),
    },

    // Management routes
    // 1. Recipes
    {
      key: "recipes",
      path: `${APP_PREFIX_PATH}/recipes/*`,
      component: React.lazy(() => import("views/app-views/recipes")),
      access: ["Brewery", "Beverage"].includes(companyType),
    },

    // 2. Sort Numbers
    {
      key: "sortNumbers",
      path: `${APP_PREFIX_PATH}/sort-numbers/*`,
      component: React.lazy(() => import("views/app-views/sortNumbers")),
      access: stages && stages.some((stage) => stage.type === "Packaging"),
    },

    // 3. Batches
    {
      key: "management.batches",
      path: `${APP_PREFIX_PATH}/management/batches/:stage`,
      component: React.lazy(() => import("views/app-views/batchManagement")),
      access: companyType !== "Generic",
    },

    // 3. Traceability
    {
      key: "management.traceability",
      path: `${APP_PREFIX_PATH}/management/traceability`,
      component: React.lazy(() => import("views/app-views/traceability")),
      access: companyType !== "Generic",
    },

    // 5. Batch Details
    {
      key: "batch.details",
      path: `${APP_PREFIX_PATH}/:stage/batch/:pk/details`,
      component: React.lazy(() => import("views/app-views/batchDetails")),
      access: true,
    },

    // 6. Data Entry
    {
      key: "data.entry",
      path: `${APP_PREFIX_PATH}/data-entry/*`,
      component: React.lazy(() => import("views/app-views/dataEntry")),
      access: ["Brewery", "Beverage"].includes(companyType),
    },

    // 7. Inventory
    {
      key: "inventory",
      path: `${APP_PREFIX_PATH}/inventory`,
      component: React.lazy(() => import("views/app-views/inventory")),
      access: ["Brewery", "Beverage"].includes(companyType),
    },

    // 8. Thresholds
    {
      key: "thresholds",
      path: `${APP_PREFIX_PATH}/thresholds/:stage`,
      component: React.lazy(() => import("views/app-views/thresholds")),
      access: true,
    },

    // 9. Employees
    {
      key: "employees",
      path: `${APP_PREFIX_PATH}/employees`,
      component: React.lazy(() => import("views/app-views/employees")),
      access: true,
    },

    // 10. Settings
    {
      key: "settings",
      path: `${APP_PREFIX_PATH}/settings/*`,
      component: React.lazy(() => import("views/app-views/settings")),
      access: true,
    },

    // BI routes
    // 1. Productivity
    {
      key: "productivity",
      path: `${APP_PREFIX_PATH}/productivity/*`,
      component: React.lazy(() => import("views/app-views/productivity")),
      access:
        ["Brewery", "Beverage"].includes(companyType) &&
        department !== "Shift Monitoring" &&
        biAccess,
    },

    // 2. Recipe Analytics
    {
      key: "recipe.analytics",
      path: `${APP_PREFIX_PATH}/recipe-analytics/:stage`,
      component: React.lazy(() => import("views/app-views/recipeAnalytics")),
      access: companyType === "Brewery" && biAccess,
    },

    // 3. Batch Comparison
    {
      key: "batch.comparison",
      path: `${APP_PREFIX_PATH}/batch-comparison/:stage`,
      component: React.lazy(() => import("views/app-views/batchComparison")),
      access: companyType === "Brewery" && biAccess,
    },

    // 4. Data Comparison
    {
      key: "data.comparison",
      path: `${APP_PREFIX_PATH}/data-comparison`,
      component: React.lazy(() => import("views/app-views/dataComparison")),
      access: true,
    },
    {
      key: "equipment.analytics",
      path: `${APP_PREFIX_PATH}/equipment-analytics`,
      component: React.lazy(() => import("views/app-views/equipmentAnalytics")),
      access: companyType === "Brewery" && biAccess,
    },

    // 5. Reports
    {
      key: "reports",
      path: `${APP_PREFIX_PATH}/reports`,
      component: React.lazy(() => import("views/app-views/reports")),
      access: companyType === "Generic",
    },

    // Not Found
    {
      key: "notFound",
      path: `${APP_PREFIX_PATH}/not-found`,
      component: React.lazy(() => import("views/app-views/notFound")),
      access: true,
    },
    // Internal Server Error
    {
      key: "serverError",
      path: `${APP_PREFIX_PATH}/server-error`,
      component: React.lazy(() =>
        import("views/app-views/InternalServerError")
      ),
      access: true,
    },
  ];
};
